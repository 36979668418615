import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // Auth
  {
    path: "/auth",
    name: "auth-layout",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login-view",
        component: () => import("@/views/Auth/LoginView.vue")
      },
      {
        path: "/register",
        name: "register-view",
        component: () => import("@/views/Auth/RegisterView.vue")
      },
      {
        path: "/auth/password-reset-request",
        name: "password-reset-request-view",
        component: () =>
          import("@/views/Auth/PasswordReset/PasswordResetRequest.vue")
      },
      {
        path: "/auth/password-reset-confirmation",
        name: "password-reset-confirmation-view",
        component: () =>
          import("@/views/Auth/PasswordReset/PasswordResetConfirmation.vue")
      },
      {
        path: "/auth/view-selection",
        name: "view-selection",
        component: () => import("@/views/Auth/ViewSelection.vue"),
        meta: {
          auth: true
        }
      }
    ]
  },
  // Projects
  {
    path: "/projects",
    name: "projects-layout",
    component: () => import("@/layouts/ProjectsLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "/projects/home",
        name: "projects-home-view",
        component: () => import("@/views/Projects/ProjectsHomeView.vue")
      },
      {
        path: "/projects/list",
        name: "projects-list-view",
        component: () => import("@/views/Projects/Kanban/ProjectsListView.vue")
      },
      {
        path: "/projects/project/:id",
        name: "project-view",
        component: () => import("@/views/Projects/Kanban/ProjectView.vue"),
        props: true
      },
      {
        path: "/projects/project-edit/:id",
        name: "project-edit-view",
        component: () => import("@/views/Projects/Kanban/ProjectEditView.vue"),
        props: true
      },
      {
        path: "/projects/project-create",
        name: "project-create-view",
        component: () => import("@/views/Projects/Kanban/ProjectCreateView.vue")
      },
      {
        path: "/projects/teams",
        name: "projects-teams-view",
        component: () => import("@/views/Projects/Kanban/ProjectsTeamsView.vue")
      },
      {
        path: "/projects/timesheet-create",
        name: "project-timesheet-create-view",
        component: () =>
          import("@/views/Projects/Timesheet/TimesheetCreateView.vue")
      },
      {
        path: "/projects/timesheet-home",
        name: "project-timesheet-home-view",
        component: () =>
          import("@/views/Projects/Timesheet/TimesheetHomeView.vue")
      },
      {
        path: "/projects/timesheet-edit/:id",
        name: "project-timesheet-edit-view",
        component: () =>
          import("@/views/Projects/Timesheet/TimesheetUpdateView.vue"),
        props: true
      }
    ]
  },
  // Employee
  {
    path: "/employee",
    name: "employee-layout",
    component: () => import("@/layouts/EmployeeLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "",
        redirect: "/employee/home"
      },
      {
        path: "home",
        name: "employee-home-view",
        component: () => import("@/views/Employee/EmployeeHomeView.vue")
      },
      {
        path: "settings",
        name: "employee-settings-view",
        component: () => import("@/views/Employee/EmployeeSettingsView.vue")
      },
      {
        path: "company-news",
        name: "employee-company-news",
        component: () => import("@/views/Employee/News/CompanyNewsView.vue")
      },
      {
        path: "company-news-post/:id",
        name: "employee-company-news-post",
        component: () => import("@/views/Employee/News/CompanyNewsPostView.vue")
      },
      {
        path: "documents",
        name: "employee-documents-view",
        component: () => import("@/views/Employee/Documents/DocumentsView.vue")
      },
      {
        path: "document/:id",
        name: "employee-document-view",
        component: () => import("@/views/Employee/Documents/DocumentView.vue")
      },
      {
        path: "team-members",
        name: "employee-team-members-view",
        component: () => import("@/views/Employee/Teams/TeamMembersView.vue")
      },
      {
        path: "training-links",
        name: "training-links",
        component: () =>
          import("@/views/Employee/Training/TrainingLinksView.vue")
      },
      {
        path: "human-resources",
        name: "employee-human-resources",
        component: () =>
          import(
            "@/views/Employee/HumanResources/ContactHumanResourcesView.vue"
          )
      },
      {
        path: "/employee/health",
        name: "Employee Health",
        component: () =>
          import("@/views/Employee/Health/EmployeeHealthView.vue")
      },
      {
        path: "/employee/health/rating-history",
        name: "EmployeeHealthRatingLogsHistoryView",
        component: () =>
          import(
            "@/views/Employee/Health/EmployeeHealthRatingLogsHistoryView.vue"
          )
      },
      // employee-kpis
      {
        path: "/employee-management/kpis",
        name: "employee-management-kpis-view",
        children: [
          {
            path: "/employee-management/kpis/upcoming-kpi",
            name: "employee-upcoming-kpi-view",
            component: () => import("@/views/Employee/KPI/UpcomingKPIView.vue"),
            props: true
          },
          {
            path: "/employee-management/kpis/performance-review",
            name: "employee-performance-review",
            component: () =>
              import("@/views/Employee/KPI/PerformanceReviewView.vue")
          },
          {
            path: "/employee-management/kpis/kpi-report",
            name: "employee-management-kpis-report-view",
            component: () => import("@/views/Employee/KPI/KPIReportView.vue")
          },
          {
            path: "/employee-management/kpis/detail-view",
            name: "employee-management-kpis-detail-view",
            component: () =>
              import("@/views/Employee/KPI/KpiReviewDetailView.vue")
          }
        ]
      },
      {
        path: "/employee/incident-report-home",
        name: "incidents",
        component: () =>
          import(
            "@/views/Employee/IncidentReport/IncidentReportListingView.vue"
          )
      },
      {
        path: "/employee/incident-report/create",
        name: "incident-report-create",
        component: () =>
          import("@/views/Employee/IncidentReport/IncidentReportFormView.vue")
      },
      {
        path: "/employee/incident-report/:id",
        name: "incident-report-view",
        component: () =>
          import("@/views/Employee/IncidentReport/IncidentReportView")
      },
      {
        path: "/employee/incident-report-edit/:id",
        name: "employee-incident-report-edit",
        component: () =>
          import("@/views/Employee/IncidentReport/IncidentReportEditView")
      },
      {
        path: "/employee/checkists-home",
        name: "employee-checklists-home",
        component: () =>
          import("@/views/Employee/CheckLists/EmployeeCheckListsView.vue")
      },
      {
        path: "/employee/checkist-view/:id",
        name: "employee-checklist-view",
        component: () =>
          import("@/views/Employee/CheckLists/CheckListsView.vue")
      }
    ]
  },
  // Website
  {
    path: "/",
    name: "website-layout",
    component: () => import("@/layouts/WebsiteLayout.vue"),
    children: [
      {
        path: "/",
        name: "home-view",
        component: () => import("@/views/Website/HomeView.vue")
      },
      {
        path: "/about",
        name: "about-view",
        component: () => import("@/views/Website/AboutView.vue")
      },
      {
        path: "/contact",
        name: "contact-view",
        component: () => import("@/views/Website/ContactView.vue")
      },
      {
        path: "/features",
        name: "features-view",
        component: () => import("@/views/Website/FeaturesView.vue")
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy-view",
        component: () => import("@/views/Website/PrivacyPolicyView.vue")
      },
      {
        path: "/terms-and-conditions",
        name: "terms-and-conditions-view",
        component: () => import("@/views/Website/TermsAndConditionsView.vue")
      },
      {
        path: "/pricing",
        name: "pricing-view",
        component: () => import("@/views/Website/Checkout/PricingView.vue")
      }
    ]
  },
  // Manager
  {
    path: "/manager",
    name: "manager-layout",
    component: () => import("@/layouts/ManagerLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "",
        redirect: "/manager/home"
      },
      {
        path: "home",
        name: "manager-home-view",
        component: () => import("@/views/Manager/ManagerHomeView.vue")
      },
      {
        path: "settings",
        name: "manager-settings-view",
        component: () => import("@/views/Manager/ManagerSettingsView.vue")
      },
      {
        path: "teams",
        name: "manager-teams-view",
        component: () => import("@/views/Manager/Teams/ManagerTeamsView.vue")
      },
      {
        path: "/manager/training",
        name: "manager-training-view",
        children: [
          {
            path: "/manager/training/training-home",
            name: "manager-training-home-view",
            component: () =>
              import("@/views/Manager/Training/ManagerTrainingLinksView.vue")
          }
        ]
      },
      {
        path: "/manager/training/:id/edit",
        name: "manager-training-links-edit",
        component: () =>
          import("@/views/Manager/Training/ManagerTrainingLinksEditView.vue"),
        props: true
      },
      {
        path: "/manager/training/create",
        name: "manager-training-create",
        component: () =>
          import("@/views/Manager/Training/ManagerTrainingCreateView.vue")
      },
      {
        path: "documents/contracts",
        name: "manager-contracts-view",
        component: () =>
          import("@/views/Manager/Documents/ManagerDocumentContractsView.vue")
      },
      {
        path: "documents/contract/:id",
        name: "manager-contract-view",
        component: () =>
          import("@/views/Manager/Documents/ManagerDocumentContractView.vue")
      },
      {
        path: "kpis",
        name: "manager-kpi-view",
        component: () => import("@/views/Manager/KPI/ManagerKPIView.vue")
      },
      // manager-kpis
      {
        path: "/manager/kpis",
        name: "manager-kpis-view",
        children: [
          {
            path: "/manager/kpis/create",
            name: "manager-kpis-view-create",
            component: () => import("@/views/Manager/KPI/KPICreateView.vue")
          },
          {
            path: "/manager/kpis/history",
            name: "manager-kpis-view-history",
            component: () => import("@/views/Manager/KPI/KPIHistoryView.vue")
          },
          {
            path: "/manager/kpis/-detail/:id",
            name: "manager-kpis-view-detail",
            component: () => import("@/views/Manager/KPI/KPIDetailView.vue"),
            props: true
          },
          {
            path: "/manager/kpis/kpi-review",
            name: "manager-kpis-review-view",
            component: () => import("@/views/Manager/KPI/KPIManagementView.vue")
          },
          {
            path: "/manager/kpis/kpi-supplier-review",
            name: "manager-kpis-supplier-review-view",
            component: () =>
              import("@/views/Manager/KPI/PerformanceReviewSupplierView.vue")
          },
          {
            path: "/manager/kpis/kpi-employee-review",
            name: "manager-kpis-employee-review-view",
            component: () =>
              import("@/views/Manager/KPI/PerformanceReviewEmployeeView.vue")
          },
          {
            path: "/manager/kpis/kpi-assign/:id",
            name: "manager-kpis-kpi-assign",
            component: () => import("@/views/Manager/KPI/KpiAssignView.vue"),
            props: true
          },
          {
            path: "/manager/kpis/detail-view/:id",
            name: "manager-kpis-detail-view",
            component: () =>
              import("@/views/Manager/KPI/KpiReviewDetailView.vue"),
            props: true
          }
        ]
      },
      {
        path: "review-employee/:id",
        name: "manager-review-employee-view",
        component: () =>
          import("@/views/Manager/KPI/ManagerReviewEmployeeView.vue")
      },
      {
        path: "broadcast",
        name: "manager-broadcast-view",
        component: () =>
          import("@/views/Manager/Broadcast/ManagerBroadcastView.vue")
      },
      {
        path: "reports",
        name: "manager-report-view",
        component: () => import("@/views/Manager/Report/ManagerReportView.vue")
      },
      {
        path: "employee/:id",
        name: "manager-employee-view",
        component: () => import("@/views/Manager/KPI/ManagerEmployeeView.vue")
      },
      {
        path: "/manager/incident-report-home",
        name: "incidents-list",
        component: () =>
          import("@/views/Manager/IncidentReport/IncidentReportListingView.vue")
      },
      {
        path: "/manager/incident-report/:id",
        name: "manager-incident-report-view",
        component: () =>
          import("@/views/Manager/IncidentReport/IncidentReportView.vue")
      },
      {
        path: "/manager/incident-report-edit/:id",
        name: "manager-incident-report-edit",
        component: () =>
          import("@/views/Manager/IncidentReport/IncidentReportEditView")
      }
    ]
  },
  // Supplier Management
  {
    path: "/supplier-management",
    name: "supplier-layout",
    component: () => import("@/layouts/SupplierLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "/supplier-management/supplier-home",
        name: "supplier-management-home-view",
        component: () => import("@/views/Supplier/SupplierHomeView.vue")
      },
      {
        path: "/supplier-management/supplier-settings",
        name: "supplier-management-settings-view",
        component: () => import("@/views/Supplier/SupplierSettingsView.vue")
      },
      // supplier-checklists
      {
        path: "/supplier-management/checklists",
        name: "supplier-management-checklist-view",
        children: [
          {
            path: "/supplier-management/checklists/checklist-home",
            name: "supplier-management-checklist-home-view",
            component: () =>
              import("@/views/Supplier/Checklist/SupplierChecklistHomeView.vue")
          },
          {
            path: "/supplier-management/checklists/todo",
            name: "supplier-management-checklist-todo",
            component: () =>
              import("@/views/Supplier/Checklist/SupplierChecklistTodoView.vue")
          },
          {
            path: "/supplier-management/checklists/update/:id",
            name: "supplier-management-checklist-update",
            component: () =>
              import(
                "@/views/Supplier/Checklist/SupplierChecklistUpdateView.vue"
              ),
            props: true
          }
        ]
      },
      // supplier-kpis
      {
        path: "/supplier-management/kpis",
        name: "supplier-management-kpis-view",
        children: [
          {
            path: "/supplier-management/kpis/kpi-report",
            name: "supplier-management-kpis-report-view",
            component: () => import("@/views/Supplier/KPI/KPIReportView.vue")
          },
          {
            path: "/supplier-management/kpis/upcoming",
            name: "supplier-management-kpis-upcoming",
            component: () => import("@/views/Supplier/KPI/UpcomingKPIView.vue")
          },
          {
            path: "/supplier-management/kpis/review",
            name: "supplier-management-kpis-review",
            component: () =>
              import("@/views/Supplier/KPI/PerformanceReviewView.vue"),
            props: true
          },
          {
            path: "/supplier-management/kpis/detail-view",
            name: "supplier-management-kpis-detail-view",
            component: () =>
              import("@/views/Supplier/KPI/KpiReviewDetailView.vue"),
            props: true
          }
        ]
      },
      // supplier-invites
      {
        path: "/supplier-management/invites",
        name: "supplier-management-invites-view",
        children: [
          {
            path: "/supplier-management/invites/home",
            name: "supplier-management-invites-view-home",
            component: () =>
              import("@/views/Supplier/Invites/SupplierInviteHomeView.vue")
          },
          {
            path: "/supplier-management/invites/detail-view/:id",
            name: "supplier-management-invites-detail-view",
            component: () =>
              import("@/views/Supplier/Invites/SupplierInviteDetailView.vue"),
            props: true
          }
        ]
      },
      // supplier company documents
      {
        path: "/supplier-management/company-document",
        name: "supplier-company-documents-view",
        children: [
          {
            path: "/supplier-management/company-document/home",
            name: "supplier-company-documents-view-home",
            component: () =>
              import("@/views/Supplier/Documents/DocumentManagementView.vue")
          },
          {
            path: "/supplier-management/company-document/list-view/:id",
            name: "supplier-company-document-list-view",
            component: () =>
              import(
                "@/views/Supplier/Documents/DocumentManagementListView.vue"
              ),
            props: true
          }
        ]
      },
      // supplier view other screens
      {
        path: "/supplier-management/supplier-management",
        name: "supplier-view",
        children: [
          {
            path: "/supplier-management/companies",
            name: "supplier-management-companies-view",
            component: () =>
              import("@/views/Supplier/Companies/CompanyManagementView.vue")
          },
          {
            path: "/supplier-management/company-management",
            name: "supplier-company-management-view",
            component: () =>
              import("@/views/Supplier/Companies/CompanyManagementView.vue")
          }
        ]
      }
    ]
  },
  // Settings Management
  {
    path: "/settings-management",
    name: "settings-layout",
    component: () => import("@/layouts/SettingsLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "/settings-management/",
        name: "settings-view",
        children: [
          {
            path: "",
            redirect: "/settings-management/company-details"
          },
          {
            path: "/settings-management/company-details",
            name: "company-details-view",
            component: () =>
              import("@/views/Settings/CompanySettings/CompanyDetails.vue")
          },
          {
            path: "/settings-management/settings-billing/billing-view",
            name: "billing-view",
            component: () => import("@/views/Settings/Billing/BillingView.vue")
          },
          {
            path: "/settings-management/settings-billing/billing-suspension",
            name: "suspension-view",
            component: () =>
              import("@/views/Settings/Billing/SuspendedView.vue")
          },
          {
            path: "/settings-management/account-management/user-roles",
            name: "settings-user-roles",
            component: () =>
              import("@/views/Settings/AccountManagement/UserRoles.vue")
          }
        ]
      }
    ]
  },
  // Human Resource
  {
    path: "/human-resource",
    name: "human-resource-layout",
    component: () => import("@/layouts/HumanResourceLayout.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "",
        redirect: "/human-resource/home"
      },
      {
        path: "/human-resource/home",
        name: "human-resource-home-view",
        component: () =>
          import("@/views/HumanResource/HumanResourceHomeView.vue")
      },
      {
        path: "/human-resource/settings",
        name: "human-resource-settings-view",
        component: () =>
          import("@/views/HumanResource/HumanResourceSettingsView.vue")
      },
      {
        path: "/human-resource/documents",
        name: "human-resource-documents-view",
        component: () =>
          import("@/views/HumanResource/Documents/DocumentsView.vue")
      },
      {
        path: "/human-resource/document/:id",
        name: "human-resource-document-view",
        component: () =>
          import("@/views/HumanResource/Documents/DocumentView.vue")
      },
      // hr-kpis
      {
        path: "/human-resource/kpis",
        name: "human-resource-kpis-view",
        children: [
          {
            path: "/human-resource/kpis/create",
            name: "human-resource-kpis-view-create",
            component: () =>
              import("@/views/HumanResource/KPI/KPICreateView.vue")
          },
          {
            path: "/human-resource/kpis/history",
            name: "human-resource-kpis-view-history",
            component: () =>
              import("@/views/HumanResource/KPI/KPIHistoryView.vue")
          },
          {
            path: "/human-resource/kpis/-detail/:id",
            name: "human-resource-kpis-view-detail",
            component: () =>
              import("@/views/HumanResource/KPI/KPIDetailView.vue"),
            props: true
          },
          {
            path: "/human-resource/kpis/kpi-review",
            name: "human-resource-kpis-review-view",
            component: () =>
              import("@/views/HumanResource/KPI/KPIManagementView.vue")
          },
          {
            path: "/human-resource/kpis/kpi-supplier-review",
            name: "human-resource-kpis-supplier-review-view",
            component: () =>
              import(
                "@/views/HumanResource/KPI/PerformanceReviewSupplierView.vue"
              )
          },
          {
            path: "/human-resource/kpis/kpi-employee-review",
            name: "human-resource-kpis-employee-review-view",
            component: () =>
              import(
                "@/views/HumanResource/KPI/PerformanceReviewEmployeeView.vue"
              )
          },
          {
            path: "/human-resource/kpis/kpi-assign/:id",
            name: "human-resource-kpis-kpi-assign",
            component: () =>
              import("@/views/HumanResource/KPI/KpiAssignView.vue"),
            props: true
          },
          {
            path: "/human-resource/kpis/detail-view/:id",
            name: "human-resource-kpis-detail-view",
            component: () =>
              import("@/views/HumanResource/KPI/KpiReviewDetailView.vue"),
            props: true
          }
        ]
      },
      {
        path: "/human-resource/kpis",
        name: "human-resource-kpis-view",
        children: [
          {
            path: "/human-resource/kpis/create",
            name: "human-resource-kpis-view-create",
            component: () =>
              import("@/views/HumanResource/KPI/KPICreateView.vue")
          },
          {
            path: "/human-resource/kpis/history",
            name: "human-resource-kpis-view-history",
            component: () =>
              import("@/views/HumanResource/KPI/KPIHistoryView.vue")
          },
          {
            path: "/human-resource/kpis/-detail/:id",
            name: "human-resource-kpis-view-detail",
            component: () =>
              import("@/views/HumanResource/KPI/KPIDetailView.vue"),
            props: true
          },
          {
            path: "/human-resource/kpis/kpi-review",
            name: "human-resource-kpis-review-view",
            component: () =>
              import("@/views/HumanResource/KPI/KPIManagementView.vue")
          },
          {
            path: "/human-resource/kpis/kpi-supplier-review",
            name: "human-resource-kpis-supplier-review-view",
            component: () =>
              import(
                "@/views/HumanResource/KPI/PerformanceReviewSupplierView.vue"
              )
          },
          {
            path: "/human-resource/kpis/kpi-employee-review",
            name: "human-resource-kpis-employee-review-view",
            component: () =>
              import(
                "@/views/HumanResource/KPI/PerformanceReviewEmployeeView.vue"
              )
          },
          {
            path: "/human-resource/kpis/kpi-assign/:id",
            name: "human-resource-kpis-kpi-assign",
            component: () =>
              import("@/views/HumanResource/KPI/KpiAssignView.vue"),
            props: true
          },
          {
            path: "/human-resource/kpis/detail-view/:id",
            name: "human-resource-kpis-detail-view",
            component: () =>
              import("@/views/HumanResource/KPI/KpiReviewDetailView.vue"),
            props: true
          }
        ]
      },
      {
        path: "/human-resource/employees",
        name: "human-resource-employees-view",
        children: [
          {
            path: "/human-resource/employees/employee-home",
            name: "human-resource-employee-home-view",
            component: () =>
              import(
                "@/views/HumanResource/Employee/HumanResourceEmployeeHomeView.vue"
              )
          },
          {
            path: "/human-resource/employees/employee-roles/:id",
            name: "human-resource-employee-roles-view",
            component: () =>
              import(
                "@/views/HumanResource/Employee/HumanResourceEmployeeRolesView.vue"
              )
          },
          {
            path: "/human-resource/employees/employee/:id",
            name: "human-resource-employee-view",
            component: () =>
              import(
                "@/views/HumanResource/Employee/HumanResourceEmployeeView.vue"
              )
          },
          {
            path: "/human-resource/employees/add-employee",
            name: "human-resource-add-employee-view",
            component: () =>
              import(
                "@/views/HumanResource/Employee/HumanResourceAddEmployeeView.vue"
              )
          },
          {
            path: "/human-resource/employees/bulk-add-employees",
            name: "human-resource-bulk-add-employee-view",
            component: () =>
              import(
                "@/views/HumanResource/Employee/HumanResourceBulkAddEmployeesView.vue"
              )
          }
        ]
      },
      {
        path: "/human-resource/company-news",
        name: "human-resource-company-news-view",
        children: [
          {
            path: "/human-resource/company-news",
            name: "human-resource-company-news-landing-view",
            component: () =>
              import(
                "@/views/HumanResource/CompanyNews/CompanyNewsLandingView.vue"
              )
          },
          {
            path: "/human-resource/company-news/create-new",
            name: "human-resource-company-create-new-view",
            component: () =>
              import(
                "@/views/HumanResource/CompanyNews/CompanyNewsCreateView.vue"
              )
          },
          {
            path: "/human-resource/company-news/edit/:id",
            name: "human-resource-company-edit-view",
            component: () =>
              import(
                "@/views/HumanResource/CompanyNews/CompanyNewsLandingView.vue"
              )
          }
        ]
      },
      {
        path: "/human-resource/broadcasts",
        name: "human-resource-broadcast-view",
        children: [
          {
            path: "/human-resource/broadcasts/broadcast-home",
            name: "human-resource-broadcast-home-view",
            component: () =>
              import(
                "@/views/HumanResource/Broadcast/HumanResourceBroadcastHomeView.vue"
              )
          },
          {
            path: "/human-resource/broadcast/send",
            name: "human-resource-broadcast-send",
            component: () =>
              import(
                "@/views/HumanResource/Broadcast/HumanResourceBroadcastSendView.vue"
              )
          }
        ]
      },
      {
        path: "/human-resource/checklists",
        name: "human-resource-checklist-view",
        children: [
          {
            path: "/human-resource/checklists/checklist-home",
            name: "human-resource-checklist-home-view",
            component: () =>
              import(
                "@/views/HumanResource/Checklist/HumanResourceChecklistHomeView.vue"
              )
          },
          {
            path: "/human-resource/checklists/assign/:id",
            name: "human-resource-checklist-assign",
            component: () =>
              import(
                "@/views/HumanResource/Checklist/HumanResourceChecklistAssignView.vue"
              ),
            props: true
          },
          {
            path: "/human-resource/checklists/create",
            name: "human-resource-checklist-create",
            component: () =>
              import(
                "@/views/HumanResource/Checklist/HumanResourceChecklistCreateView.vue"
              )
          },
          {
            path: "/human-resource/training/create",
            name: "human-resource-training-create",
            component: () =>
              import(
                "@/views/HumanResource/Training/HumanResourceTrainingCreateView.vue"
              )
          },
          {
            path: "/human-resource/checklists/update/:id",
            name: "human-resource-checklist-update",
            component: () =>
              import(
                "@/views/HumanResource/Checklist/HumanResourceChecklistUpdateView.vue"
              ),
            props: true
          }
        ]
      },
      {
        path: "/human-resource/departments",
        name: "human-resource-department-view",
        children: [
          {
            path: "/human-resource/departments/department-home",
            name: "human-resource-department-home-view",
            component: () =>
              import(
                "@/views/HumanResource/Department/HumanResourceDepartmentHomeView.vue"
              )
          },
          {
            path: "/human-resource/departments/link/:id",
            name: "human-resource-department-link",
            component: () =>
              import(
                "@/views/HumanResource/Department/HumanResourceDepartmentLinkView.vue"
              ),
            props: true
          },
          {
            path: "/human-resource/departments/create",
            name: "human-resource-department-create",
            component: () =>
              import(
                "@/views/HumanResource/Department/HumanResourceDepartmentCreateView.vue"
              )
          },
          {
            path: "/human-resource/departments/update/:id",
            name: "human-resource-department-update",
            component: () =>
              import(
                "@/views/HumanResource/Department/HumanResourceDepartmentUpdateView.vue"
              ),
            props: true
          }
        ]
      },
      {
        path: "/human-resource/teams",
        name: "human-resource-team-view",
        children: [
          {
            path: "/human-resource/teams/team-home",
            name: "human-resource-team-home-view",
            component: () =>
              import("@/views/HumanResource/Team/HumanResourceTeamHomeView.vue")
          },
          {
            path: "/human-resource/teams/link/:id",
            name: "human-resource-team-link",
            component: () =>
              import(
                "@/views/HumanResource/Team/HumanResourceTeamLinkView.vue"
              ),
            props: true
          },
          {
            path: "/human-resource/teams/employees/:id",
            name: "human-resource-team-employees",
            component: () =>
              import(
                "@/views/HumanResource/Team/HumanResourceTeamEmployeesView.vue"
              ),
            props: true
          },
          {
            path: "/human-resource/teams/create",
            name: "human-resource-team-create",
            component: () =>
              import(
                "@/views/HumanResource/Team/HumanResourceTeamCreateView.vue"
              )
          },
          {
            path: "/human-resource/teams/update/:id",
            name: "human-resource-team-update",
            component: () =>
              import(
                "@/views/HumanResource/Team/HumanResourceTeamUpdateView.vue"
              ),
            props: true
          }
        ]
      },
      {
        path: "/human-resource/training",
        name: "human-resource-training-view",
        children: [
          {
            path: "/human-resource/training/training-home",
            name: "human-resource-training-home-view",
            component: () =>
              import(
                "@/views/HumanResource/Training/HumanResourceTrainingLinksView.vue"
              )
          }
        ]
      },
      {
        path: "/human-resource/training/:id/edit",
        name: "human-resource-training-links-edit",
        component: () =>
          import(
            "@/views/HumanResource/Training/HumanResourceTrainingLinksEditView.vue"
          ),
        props: true
      },
      {
        path: "/human-resource/incident-report-home",
        name: "human-resource-incident-report-view",
        component: () =>
          import(
            "@/views/HumanResource/IncidentReport/IncidentReportListingView.vue"
          )
      },
      {
        path: "/human-resource/incident-report/:id",
        name: "human-resource-incident-report-details-view",
        component: () =>
          import(
            "@/views/HumanResource/IncidentReport/IncidentReportDetailsView.vue"
          )
      },
      {
        path: "/human-resource/incident-report-edit/:id",
        name: "human-resource-incident-report-edit",
        component: () =>
          import("@/views/HumanResource/IncidentReport/IncidentReportEditView")
      },
      {
        path: "/human-resource/timesheet-home",
        name: "human-resource-timesheet-home-view",
        component: () =>
          import(
            "@/views/HumanResource/Timesheet/HumanResourceTimesheetHomeView.vue"
          )
      }
    ]
  },
  // Common
  {
    path: "/suspended",
    name: "suspend-view",
    component: () => import("@/views/Suspended.vue")
  },
  {
    path: "/not-authorised",
    name: "not-authorised",
    component: () => import("@/views/NotAuthorised.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/views/PageNotFound.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
});

export default app => {
  app.router = router;
  app.use(router);
};
